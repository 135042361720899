
html {
    font-size: 14px;
}
.title{
    font-size: 22px;
    font-weight: bold;
    color: #232f3e;
    padding-left: 15px;
}
.armada-tile-value{
    color: #232f3e;
}
.container{
    max-width: 95vw !important;
}
// body::-webkit-scrollbar {
//     width: 10px;
//   }
// body::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
// }
// body::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid darkgrey;
// }
body .ui-tooltip {
  max-width: 38.5em !important;
  font-size: 13px !important;
  font-weight: 300;
}